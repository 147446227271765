<template>
    <div id="anchor-stat">

        <el-form ref="form" :model="form" label-width="80px" inline>
            <el-form-item label="录入时间">
                <el-date-picker v-model="form.startTime" type="datetime" placeholder=""
                    value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
                -
                <el-date-picker v-model="form.endTime" type="datetime" placeholder=""
                    value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>

            </el-form-item>
            <el-form-item>
    <el-button type="primary" @click="loadList">查询</el-button>
  </el-form-item>

        </el-form>

        <el-table :data="page.list" border style="width: 50%;">
            <el-table-column label="经纪人/外宣">
                <template v-slot="row">
                    [{{ row.row.agentId }}] {{ row.row.agentNickName }}
                </template>
            </el-table-column>
            <el-table-column label="总数" width="100" prop="total">

            </el-table-column>

            <el-table-column label="已分配" width="100" prop="haveStudioNums">

            </el-table-column>
            <el-table-column label="未分配" width="100" prop="noStudioNums">

            </el-table-column>

            <el-table-column label="达标数量" width="100" prop="matchNums">

            </el-table-column>



        </el-table>
    </div>
</template>
<script>
export default {
    name: "AUList",
    data() {
        return {

            form: {
                startTime: null,
                endTime: null,
            },


            page: {
                list: null,
            },
        };
    },

    methods: {
        loadList() {

            this.axios.get("/anchor/stat", { params: this.form }).then((resp) => {
                if (resp.data.success) {

                    this.page = resp.data.data;
                } else {
                    this.$message.error(resp.data.msg);
                }
            });
        },



    },

    created() {
        this.loadList();
    },
};
</script>

<style scoped></style>